.carouselBoxTitle {
  font-weight: 600;
  font-size: 20px;
  background-color: rgba(51, 51, 51, 0.6);  /* #333333; */
  color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  width: 50%;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-shadow: none;
  text-align: left;
}


.carouselBoxInformation {
  font-weight: 400px;
  font-size: 16px;
  background-color: #ebe8df;
  color: #514e44;
  padding: 20px;
  border-radius: 8px;
  text-shadow: none;
  text-align: left;
}

@media screen and (max-width: 992px) {
  .carouselBoxTitle {
    font-size: 14px;
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 768px) {
  .carouselBoxTitle {
    font-size: 12px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 600px) {
  .carouselBoxTitle {
    display: none;
  }
}
