body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  background-color: #ebe8df;
  color: #333333;
}

a {
  text-decoration: none;
}

/* unvisited link */
a:link {
    color: #832924;
}

/* visited link */
a:visited {
    color: #832924;
}

/* mouse over link */
a:hover {
    color: #aeaeae;
    text-decoration: none;
}

/* selected link */
a:active {
    color: #832924;
}



/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
